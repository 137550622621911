<template>
  <div class="container">
    <el-tabs v-model="activeName"
             type="card">
      <el-tab-pane label="查资格"
                   name="first">
        <div class="tab-page-title">
          查询条件
        </div>
        <el-form ref="form"
                 style="width:400px;margin-top:15px;"
                 size="small"
                 :rules="rules"
                 :model="form"
                 label-position="left"
                 label-width="130px">
          <el-form-item label="微邮惠活动ID："
                        prop="activity_id">
            <el-input v-model="form.activity_id"></el-input>
          </el-form-item>
          <el-form-item label="邮政活动ID："
                        prop="act_id">
            <el-input v-model="form.act_id"></el-input>
          </el-form-item>
          <el-form-item label="手机号码："
                        prop="tel">
            <el-input v-model="form.tel"></el-input>
          </el-form-item>
          <el-form-item label="身份证：">
            <el-input v-model="form.id_code"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       size="large"
                       @click="onSubmit('form')">查询</el-button>
          </el-form-item>
        </el-form>
        <div class="tab-page-title">
          查询结果
        </div>
        <div style="padding:15px"
             v-if="res">
          {{ res.status | statusResult}}
        </div>
        <div class="tab-page-title">
          查询报文
        </div>
        <div v-if="res"
             style="padding:15px">
          请求报文：{{res.req}}
        </div>
        <div v-if="res"
             style="padding:15px">
          返回报文：{{res.res}}
        </div>

      </el-tab-pane>
      <el-tab-pane label="查会员"
                   name="second">
        <div class="tab-page-title">
          查询条件
        </div>
        <el-form ref="form2"
                 style="width:400px;margin-top:15px;"
                 size="small"
                 :rules="rules2"
                 :model="form2"
                 label-position="left"
                 label-width="120px">
          <el-form-item label="手机号码："
                        prop="tel">
            <el-input v-model="form2.tel"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       size="large"
                       @click="onSubmit2('form2')">查询</el-button>
          </el-form-item>
        </el-form>
        <div class="tab-page-title">
          查询结果
        </div>
        <div style="padding:15px"
             v-if="res2">
          {{ res2.status | statusResult2 }}
        </div>
        <div class="tab-page-title">
          查询报文
        </div>
        <div v-if="res2"
             style="padding:15px">
          请求报文：{{res2.req}}
        </div>
        <div v-if="res2"
             style="padding:15px">
          返回报文：{{res2.res}}
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { prefectureQuery } from '@/api/market'
export default {
  name: 'Query',
  data () {
    return {
      searchParams: {},
      activeName: 'first',
      stock_id: '',
      stock_id2: '',
      detail: null,
      detail2: null,
      form: {
        tel: '',
        id_code: '',
        act_id: '',
        activity_id: ''
      },
      form2: {
        tel: ''
      },
      rules: {
        activity_id: [
          { required: true, message: '请输入微邮惠活动ID', trigger: 'blur' }
        ],
        act_id: [
          { required: true, message: '请输入邮政活动ID', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        id_code: [
          { required: true, message: '请输入身份证', trigger: 'blur' }
        ]
      },
      rules2: {
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ]
      },
      res: null,
      res2: null
    }
  },
  filters: {
    statusResult (name) {
      let str = ''
      switch (name) {
        case 'yes':
          str = '有资格'
          break
        case 'no':
          str = '无资格'
          break
        case 'appointment':
          str = '资格已预约'
          break
        default:
          str = ''
          break
      }
      return str
    },
    statusResult2 (name) {
      let str = ''
      switch (name) {
        case 'yes':
          str = '会员存在'
          break
        case 'no':
          str = '会员不存在'
          break
        default:
          str = ''
          break
      }
      return str
    }
  },
  components: {
  },
  methods: {
    async onSubmit (formName) {
      this.res = null
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await prefectureQuery({
            type: 1,
            ...this.form
          })
          if (res.meta.code === 0) {
            this.res = res.data
          } else {
            this.$message.error(res.meta.msg)
          }
        }
      })
    },
    async onSubmit2 (formName) {
      this.res2 = null
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await prefectureQuery({
            type: 2,
            ...this.form2
          })
          if (res.meta.code === 0) {
            this.res2 = res.data
          } else {
            this.$message.error(res.meta.msg)
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.tab-page-title {
  width: 400px;
  padding: 15px 0px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #f7f7f7;
}
</style>
